import { map, Observable, timer } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { collection, doc, docData, Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class WeddingService {
  firestore: Firestore = inject(Firestore);
  schedule$: Observable<any>;
  details$: Observable<any>;
  faq$: Observable<any>;
  timeUntilWedding$: Observable<string>;

  constructor() {}

  getWeddingFAQ() {
    const weddingCollection = collection(this.firestore, 'brenda-y-jose');
    const eventDoct = doc(weddingCollection, 'event-faq');
    this.faq$ = docData(eventDoct).pipe(map((res: any) => res.faq));
  }

  getWeddingSchedule() {
    const weddingCollection = collection(this.firestore, 'brenda-y-jose');
    const eventDoct = doc(weddingCollection, 'event-schedule');
    this.schedule$ = docData(eventDoct).pipe(map((res: any) => res.schedule)).pipe(
      map((res: any) => {
        // sort by time in 24h format
        return res.sort((a: any, b: any) => {
          const aTime = a.time.split(':');
          const bTime = b.time.split(':');
          return aTime[0] - bTime[0];
        });
      })
    );
  }

  getWeddingDetails() {
    const weddingCollection = collection(this.firestore, 'brenda-y-jose');
    const eventDoct = doc(weddingCollection, 'event-details');
    this.details$ = docData(eventDoct);
    this.details$.subscribe((res: any) => {
      this.timeUntilWedding$ = timer(0, 1000).pipe(
        map(() => {
          const now = new Date();
          const diff =
            new Date(res.eventDate.seconds * 1000).getTime() - now.getTime();
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          return `${days} días ${hours} horas ${minutes} minutos`;
        })
      );
    });
  }
}
