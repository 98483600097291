import { WeddingService } from './../services/wedding.service';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventResolver implements Resolve<boolean> {
  constructor(private weddingService: WeddingService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.weddingService.getWeddingDetails();
    this.weddingService.getWeddingSchedule();
    this.weddingService.getWeddingFAQ();
    return of(true);
  }
}
