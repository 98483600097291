export const environment = {
  firebase: {
    projectId: 'nos-casamos-ab7f0',
    appId: '1:600646242493:web:e906a3cfe18b4ea77d27d4',
    storageBucket: 'nos-casamos-ab7f0.appspot.com',
    apiKey: 'AIzaSyC5Wa_ZAmuBgV_bKlpMOr-jOAFNv3H5aDg',
    authDomain: 'nos-casamos-ab7f0.firebaseapp.com',
    messagingSenderId: '600646242493',
    measurementId: 'G-K7TEYQDY45',
  },
  production: false,
};
