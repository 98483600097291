import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventResolver } from './shared/resolvers/event.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: { event: EventResolver },
    loadComponent: () =>
      import('./event/event.component').then((m) => m.EventComponent),
  },
  {
    path: 'rsvp',
    loadComponent: () =>
      import('./rsvp/rsvp.component').then((m) => m.RsvpComponent),
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./admin/admin.component').then((m) => m.AdminComponent),
      },
      {
        path: 'invitados',
        loadComponent: () =>
          import('./admin/guest-list/guest-list.component').then(
            (m) => m.GuestListComponent
          ),
      },
      {
        path: 'mesas',
        loadComponent: () =>
          import('./admin/seating/seating.component').then(
            (m) => m.SeatingComponent
          ),
      },
      {
        path: 'presupuesto',
        loadComponent: () =>
          import('./admin/budget/budget.component').then(
            (m) => m.BudgetComponent
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
